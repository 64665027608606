<template>
    <div>
        <div >
            <span class="price">￥{{arrs.price.toFixed(2)}}</span>
            <div style="margin-bottom:30px;">
                <van-button plain type="info" size="small" @click="$router.push({name:'refueingDetail'})">订单详情 ></van-button>
            </div>
        </div>
        <div class="card">
            <van-cell-group>
                <van-cell title="SIM卡号" :value="arrs.msisdn"/>
                <van-cell title="ICCID" :value="arrs.iccid"/>
                <van-cell title="加油包套餐" :value="arrs.packName"/>
                <van-cell title="数量" :value="arrs.period"/>
                <van-cell title="加油包系数" :value="arrs.discount"/>
                <van-cell title="单价" :value="`￥${arrs.firstPrice}元`"/>
            </van-cell-group>
        </div>
        <div class="button">
            <van-button color="#EB5E00" size="large" @click="submit">返回首页</van-button>
        </div>
    </div>
</template>
<script>
import { Toast } from "vant";
import wx from "weixin-js-sdk";
export default {
    name:'payRefueing',
    data() {
        return {
            arrs:{},
            chargeOrderId:'',
            timer:Object
        }
    },
    methods: {
        submit(){
            //创建账户充值业务订单=>创建支付订单=>创建消费订单
            localStorage.removeItem('refueing');
            this.$router.push({name:'Home'});
        }
    },
    created() {
        this.$store.commit('setPageTitle', "支付订单");
        if(!localStorage.refueing){
            this.$toast.fail("未发现订单");
            this.$router.push({name:'refueingCharge'})
        }else{
            this.arrs = JSON.parse(localStorage.refueing)
        }
    }
}
</script>
<style lang="scss" scoped>
.price{
    
    color: #EB5E00;font-size: 60px;
    
}
.card{
    text-align: left;
}
.button{
    margin-top: 50px
}
</style>
    
